import React, {useState} from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { Container, Image, Button, Modal, Form } from "react-bootstrap";
import CallToActionBanner from "./CallToActionBanner";
import GeneratedByBanner from "./GeneratedByBanner";
import Disclaimer from "./modals/Disclaimer";
import mixpanel from 'mixpanel-browser';
// mixpanel.identify()



const MainScreen = ({ restaurant, setHasRestrictions }) => {
  
  const history = useHistory();
  const [show, setShow] = useState(false);
  return (
    <section className="blur-overlay center-flex" id="mainScreenComponent" style={{
      backgroundImage: `url(${
        restaurant.bg ? restaurant.bg.url : "img/generic-bg.jpg"
      })`}}>
      <div className="main-screen-component-wrapper center-flex" >
        
          <Image
            className="restaurant-logo img-drop-shadow"
            src={
              restaurant.logo ? restaurant.logo.url : null
            }
          />
        <p onClick={function() {
            setShow(currentShow => !currentShow)
          }} className="agree-to-terms hover">By clicking <strong>View Allergy Menu</strong>, you confirm you have read and agree to MyMeal's terms and conditions. <br></br>Click here to view our disclaimer.</p>
        <div className="full-width-center-flex">
          <button
            className="styled-button bottom-box-shadow"
            style={{ backgroundColor: restaurant.primaryColor }}
            onClick={function() {{
              setHasRestrictions(true);
              mixpanel.track("Restaurant Page View", {
                'Restaurant': `${restaurant.name}`
              })
            }
              
            }}
            
          >
            View <strong>Allergy Menu →</strong>
          </button>
        </div>
      </div>
      
      <GeneratedByBanner></GeneratedByBanner>
      { show ?<div className="disclaimer-overlay-window ">
        <div className="disclaimer-wrapper">
      
        <button className="hover disclaimer-pop-up-close-button" onClick={function() {
            setShow(currentShow => !currentShow)
          }}>X</button>
          <Disclaimer
          primaryColor={restaurant.primaryColor}
          location={location}
          history={history}
          setShow={!setShow}
          
        ></Disclaimer></div></div> : "" }
      
      
    </section>
     
  );
};




const mapStateToProps = (state) => ({});
const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(MainScreen);
