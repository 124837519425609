import React, { useState, useRef, useEffect, rgba } from "react";
import { connect } from "react-redux";
import { Redirect, useLocation, useHistory } from "react-router-dom";
import { Container, Row, Col, Image, Button, Tabs, Tab } from "react-bootstrap";
import MenuWindow from "./MenuWindow";
import "react-loading-skeleton/dist/skeleton.css";
import { setSelectedAllergy } from "../../redux/reducers/frontend";
import KitchenProceduresWindow from "./KitchenProceduresWindow";
import CallToActionBanner from "./CallToActionBanner";
import mixpanel from 'mixpanel-browser';


const OrderMenu = ({ restaurant, categories, selectedAllergies, setSelectedAllergies }) => {
  window.scrollTo(0, 0);
  const location = useLocation();
  const history = useHistory();

  if (!restaurant.id) {
    return <Redirect to={`${location.pathname.replace("/menu", "")}`} />;
  }

  const timer = useRef(null);
  const handleOnIdle = (event) => {
    setInactiveShow(true);
    timer.current = setTimeout(() => {
      history.push(`${location.pathname.replace("/menu", "")}`);
    }, 10000);
  };

  const safeColor = "#007B2A";

  const [activeCategory, setActiveCategory] = useState(categories[0]);
  const [modalShow, setModalShow] = useState(false);
  const [inactiveShow, setInactiveShow] = useState(false);

  const categoryRefs = categories.map(({ name }) => useRef(name));
  
  const [show, setShow] = useState(false);
  const addressSearchUrl = "https://www.google.com/maps/search/" + restaurant.locations[0].address;

  


  return (
    <section id="orderMenuComponent">
      <div className="restaurant-message-banner bottom-box-shadow" style={{backgroundColor: restaurant.primaryColor}}>
        <p className="restaurant-message">{restaurant.locations[0].menu.dedicatedFrom}</p>
      </div>
    {/* MAIN HEADER */}
    <div className="blur-overlay">
            
      <div className="menu-header blur-overlay" style={{
          backgroundImage: `url(${
            restaurant.bg ? restaurant.bg.url : "/img/generic-bg.jpg"
          })`,
        }}>
      <div className="menu-header-contents">
        <div className="menu-header-logo-container">
        <div className="center-flex hover" onClick={() =>
              history.push(`${location.pathname.replace("/menu", "")}`)
            }>
            <img
              className="menu-header-logo img-drop-shadow"
              src={
                restaurant.logo
                  ? restaurant.logo.url
                  : "/img/back-arrow.png"
              }
              style={{width: `${restaurant.logo ? "75px" : "25px" }`}}
            />
            </div>
          
        </div>
        <div className="menu-header-title-container flex-center">
            <h1>{restaurant.name}</h1>
            <a style={{color: 'black', textDecoration: 'none', cursor: "pointer", textAlign: "center"}} href={addressSearchUrl} target='_blank'>
              <p style={{margin: "0px"}}>
                {restaurant.locations[0].streetOne ? (<span>{restaurant.locations[0].streetOne}</span>): null} 
                {restaurant.locations[0].streetOne && restaurant.locations[0].streetTwo ? <>,&nbsp;</> : null}
                {restaurant.locations[0].streetTwo ? (<span> {restaurant.locations[0].streetTwo}</span>) : null}
              </p>
              <p style={{margin: "0px"}}>
              {restaurant.locations[0].city ? (<span>{restaurant.locations[0].city}</span>): null} 
              {restaurant.locations[0].city && restaurant.locations[0].state ? <>,&nbsp;</> : null}
              {restaurant.locations[0].state ? (<span>{restaurant.locations[0].state}</span>): null} 
              {restaurant.locations[0].state && restaurant.locations[0].zip ? <>,&nbsp;</> : null}
              {/* {restaurant.locations[0].city && restaurant.locations[0].zip ? <>,&nbsp;</> : null} */}
              {restaurant.locations[0].zip ? (<span>{restaurant.locations[0].zip}</span>): null} 
              </p>
            </a>
            
        </div>
        
        {restaurant.locations[0].menu.orderNow ? (
          <div className="menu-header-order-button-container center-flex">
            <button className="styled-button bottom-box-shadow" style={{backgroundColor: restaurant.primaryColor}}
            onClick={function() {{mixpanel.track('Order Online Clicked', {
              'Restaurant': `${restaurant.name}`
            })}}
            }>
          <a
            style={{
              color: "white",
              fontSize: "14px",
              textDecoration: "none"
            }}
            href={restaurant.locations[0].menu.orderNow}
            target="_blank"
          >
            Order Online
          </a></button></div>
          ) : null}
        
        
      </div>
      </div>
    </div>

           
            

            
   
    
{/* MENU NAVIGATION STICKY */}
<div className="menu-nav-container" style={{}}>
      <div className="menu-nav-tabs">
        {restaurant.locations[0].country && restaurant.locations[0].country != "United States"  ? <div className="last-updated"><strong>Last Updated:</strong> {restaurant.locations[0].country} </div> : null}
      
        <div className="menu-tab-container">
          <div className={`menu-tab ${show ? "" : "active"}`}
          onClick={function() {
            setShow(currentShow => !currentShow)
          }}
          style={{backgroundColor: show? '#e2e2e2' : restaurant.primaryColor}}
          
          >Menu</div>

          <div className={`menu-tab ${show ? "active" : ""}`}
          onClick={() => setShow(currentShow => !currentShow)}
          style={{backgroundColor: !show? '#e2e2e2' : restaurant.primaryColor}}
          >Kitchen Procedures</div>
        </div>
        
      </div>
    </div>
    
    
    {/* SELECTED WINDOW */}
    <Container className="tab-section-window ">
        { show ? <KitchenProceduresWindow/> : <MenuWindow></MenuWindow> }
      </Container>
      <div style={{height: "100px"}}></div>
      <CallToActionBanner></CallToActionBanner>
      
    </section>
  );
};

const changeStyles = () => {
  let element = document.getElementById('menu-tab-button')
  console.log("clicked")
  ReactDOM.findDOMNode(element).style.backgroundColor = this.state.isClicked?'black' : 'white'
}

const mapState = (state) => {
  const { restaurant, categories, selectedAllergies } = state.frontend;
  return {
    restaurant,
    categories,
    selectedAllergies,
  };
};



const mapDispatchToProps = {};

export default connect(mapState, mapDispatchToProps)(OrderMenu);
