import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { fetchFrontendCategoryMenuitems } from "../../redux/reducers/frontend";
import menuitemFilter from "../../utils/menuitemFilter";
import MenuItemCard from "./MenuItemCard";

export var totalItems = 0;


export const OverflowButton = ({
  category,
  getMenuitems,
  selectedAllergies,
  primaryColor,
  categoryRef,
}) => {
    function handleClick(value) {
        var childRef = value;
        // ref.scrollTo({
        //     behavior: "smooth",
        //     right: 200,
            
        //   });
        // console.log('You clicked times!' + childRef);
      }
    
  return (
    
    <div  className="item-arrow-container hover"
        
    >  
        <img src="/img/back-arrow.png"
            style={{width: "20px", transform: "rotate(180deg)"}}
        />
    </div> 

  );
  
};


const mapStateToProps = (state) => {
    const { selectedAllergies } = state.frontend;
    return {
      selectedAllergies,
    };
  };
  
  const mapDispatchToProps = (dispatch) => {
    return {
      getMenuitems(data) {
        dispatch(fetchFrontendCategoryMenuitems(data));
      }, 
    };
  };


export default connect(mapStateToProps, mapDispatchToProps)(OverflowButton);
