import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { fetchCorporation } from "../../redux/reducers/corporation";
import { Link, useHistory } from "react-router-dom";
import CreateNewRestaurant from "../modals/CreateNewRestaurant";
import EditCorporation from "../modals/EditCorporation";
export const Corporation = ({
  getCorporation,
  match,
  isLoading,
  corporation,
}) => {
  const history = useHistory();
  const { corporationId } = match.params;
  useEffect(() => {
    getCorporation(corporationId);
  }, []);

  if (isLoading) {
    return <></>;
  }
  return (
    <section id="corporationComponent">
      <div>
          <div className="center-flex-start page-path-container">
            <div onClick={() => history.push("/")}>Dashboard</div>{" "}
            <p>&nbsp;/&nbsp;</p>
            <div className="active-breadcrumb">{corporation.name}</div>
          </div>
          <div className="space-between-flex flex-break" >
            <h1>Restaurants</h1>
            <div className="flex-center-end">
              <EditCorporation corporation={corporation} />
              <CreateNewRestaurant
                corporationId={corporationId}
                corporationCCP={corporation.crossContactProcedure}
              />
            </div>
          </div>
      </div>

      <div>
        {corporation.restaurants &&
          corporation.restaurants.map((restaurant, index) => (
            <div key={index} className="space-between-flex corporations hover" onClick={() =>history.push(`/corporations/${corporationId}/restaurants/${restaurant.id}`)}>

                <div className="center-flex-start" >
                  <img
                    style={{ width: "auto", height: 50 }}
                    src={
                      restaurant.logo
                        ? restaurant.logo.url
                        : "/icons/gallery.png"
                    }
                    className="img-fluid rounded shadow"
                  />

                  <p className="hover-text p2-text">&nbsp; {restaurant.name}</p>
                </div>
                <button className="backend-styled-edit-button" >
                  View
                </button>
            </div>
            
          ))}
      </div>
    </section>
  );
};

const mapState = (state) => {
  const { corporation, isLoading } = state.corporation;
  return {
    isLoading,
    corporation,
  };
};

const mapDispatch = (dispatch) => {
  return {
    getCorporation(corporationId) {
      dispatch(fetchCorporation(corporationId));
    },
    uploadImages(body) {
      dispatch(uploadFiles(body));
    },
    addRestaurant(data) {
      dispatch(createRestaurant(data));
    },
  };
};

export default connect(mapState, mapDispatch)(Corporation);
