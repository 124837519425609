import React, { useState } from "react";
import { connect } from "react-redux";
import { Button, Modal, Container, Form } from "react-bootstrap";
import { updateCorporation } from "../../redux/reducers/corporation";
import { moveAndDuplicateMenuitem } from "../../redux/reducers/category";

const MoveAndDuplicateMenuitem = ({
  menuitemId,
  menuCategories,
  moveMenuitem,
}) => {
  const [modalShow, setModalShow] = useState(false);
  const [moveToCategory, setMoveToCategory] = useState("");

  const handleMoveMenuitem = () => {
    moveMenuitem({ menuitemId, categoryId: moveToCategory });
    setModalShow(false);
  };
  return (
    <>
      <Button
      className="backend-styled-edit-button"
        variant="primary"
        onClick={() => setModalShow(true)}
      >
        Duplicate To...
      </Button>

      <Modal
        className="noscroll modal-window"
        show={modalShow}
        onHide={() => setModalShow(false)}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Container className="modal-container">
        <Modal.Header className="space-between-flex">
          <h3>Duplicate Menu Item To:</h3>
          <button className="backend-styled-button" onClick={() => setModalShow(false)}>X</button>
        </Modal.Header>
        <Modal.Body class="center-flex-start flex-break">
          <Form.Label style={{marginRight: "10px"}}>Category</Form.Label>
          <Form.Select
            aria-label="Select Menu"
            onChange={({ target: { value } }) => setMoveToCategory(value)}
            style={{ width: "50%" }}
            value={moveToCategory}
          >
            <option>Select category</option>
            {menuCategories.map((category) => (
              <option key={category.id} value={category.id}>
                {category.name}
              </option>
            ))}
          </Form.Select>
        </Modal.Body>
        <Modal.Footer>
          <br></br>
          <button onClick={handleMoveMenuitem} className="backend-styled-button">Duplicate</button>
        </Modal.Footer>
        </Container>
      </Modal>
    </>
  );
};

const mapState = (state) => {
  return {};
};
const mapDispatch = (dispatch) => {
  return {
    moveMenuitem(data) {
      dispatch(moveAndDuplicateMenuitem(data));
    },
  };
};

export default connect(mapState, mapDispatch)(MoveAndDuplicateMenuitem);
